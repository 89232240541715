<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useListTable from '@/comp-functions/useListTable';
import moment from 'moment'
import DatePicker from "vue2-datepicker";
import { ref, onMounted } from '@vue/composition-api'
import {formatNumber} from '@/utils/formatter'

export default {
  page: {
    title: "Price List",
    meta: [{ name: "description", content: appConfig.description }]
  },
  setup() {
     const { 
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists } = useListTable({ url: false })

    let tableColumns = [
      { key: "sprm_createdatdtm", label: "Upload Date", thClass: 'text-center', tdClass: 'text-center', sortable: true},
      { key: "vnd_name", label: "Airline", thClass: 'text-center', tdClass: 'text-center', sortable: true },
      { key: "sprm_effectivedate", label: "Effective Date", thClass: 'text-center', tdClass: 'text-center', sortable: true},
      { key: "route", label: "Route", thClass: 'text-center', tdClass: 'text-center', sortable: true },
      { key: "sprd_price_kg", label: "Tariff PerKg", thClass: 'text-center', tdClass: 'text-center', sortable: true,
        formatter: value => {return formatNumber(value)}
      },
      { key: "usr_fullname", label: "Upload By", thClass: 'text-center', tdClass: 'text-center', sortable: true },
    ]
    const columnToBeFilter = [
      'sprm_createdatdtm',
      'vnd_name',
      'sprm_effectivedate',
      'route',
      'sprd_price_kg'
    ]
    const date1 = new Date()
    const date2 = new Date()
    date1.setDate(date1.getDate() - 10);
    date2.setDate(date2.getDate() + 10);

    const paramdate = ref([moment(date1).format('DD-MM-YYYY'), moment(date2).format('DD-MM-YYYY')])
    const reloaddatalist = async () => {
      let url = '/master/scheduleprice'
      let params = {
        start_schdate: paramdate.value[0],
        end_schdate:  paramdate.value[1],
      }
      await fetchLists(url,params)
    }
    onMounted(() => {
      reloaddatalist()
    })

    return {
      tableColumns,
      columnToBeFilter,
      paramdate,
      reloaddatalist,
      formatNumber,
       isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists

      // ...useListTable({
      //   url: 'master/schedule'
      // }),
    }
  },
    components: { Layout, PageHeader, DatePicker },
  data() {
    return {
      title: "Price List",
      items: [],
      filterOn: []
    };
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalList = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!--
            <b-button href="/masterdata/vendorschedule/add" variant="light">
              <i class="ri-add-line align-middle mr-2"></i> Add New
            </b-button>
            &nbsp;-->
            <router-link class="btn btn-light" :to="{ name: 'appsmd-scheduleprice-upload'}"><i class="ri-add-line align-middle mr-2"></i> Upload</router-link>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <label class="col-md-10 col-sm-12 d-inline-flex align-items-center p-0">
                  Departure Date :
                  <date-picker class="col-md-7" @close="reloaddatalist()"  v-model="paramdate" format='DD-MM-YYYY' valueType="format"  range append-to-body lang="en"></date-picker>
                </label>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="searchQuery"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                no-provider-paging
                no-provider-filtering
                :fields="tableColumns"
                :items="itemLists"
                :per-page="perPage"
                :current-page="currentPage"
                :filter="searchQuery"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                responsive
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                :busy="isBusy"
                empty-text="No matching records found"
                :sort-desc.sync="isSortDirDesc"
                class="position-relative font-size-13"
              >
                <template #cell(route)="data">
                  {{ data.item.sprd_origportinit }} - {{ data.item.sprd_destportinit }}
                </template>
              </b-table>
            </div>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center col-md-12">
                   Showing
                    <b-form-select class="col-sm-5 col-md-2" v-model="perPage" size="sm" :options="perPageOptions"></b-form-select>{{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
                  </label>
                </div>
              </div>

              <div class="col-md-6 col-xs-12">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="totalList" :per-page="perPage" ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>